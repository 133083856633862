<template>
  <div class="card card-height">
    <div class="table-responsive">
      <table class="table custom-table-padding">
        <thead>
        <tr>
          <th>Role</th>
          <th>Team Member</th>
          <th>Est. Time (Hrs.)</th>
          <th>Actual Time (Hrs.)</th>
          <th>Hourly Rate</th>
          <th>Actual Team Cost</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in tableItems" :key="i">
          <td>{{ item.role_name }}</td>
          <td>{{ item.full_name }}</td>
          <td>{{ item.estimated_time }}</td>
          <td>{{ item.total_hours }}</td>
          <td>{{ commaFormat(item.contact_person_fees) }}</td>
          <td>{{ commaFormat(item.total_hours * item.contact_person_fees) }}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th colspan="2" class="text-center">Total</th>
          <th>{{ sum('estimated_time') }}</th>
          <th>{{ sum('total_hours') }}</th>
          <th>{{ commaFormat(sum('contact_person_fees')) }}</th>
          <th>{{ commaFormat(totalSum()) }}</th>
        </tr>
        </tfoot>
      </table>
      <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";

const props = defineProps({
  tableItems: {
    type: Array,
    default: []
  }
})

const {commaFormat} = figureFormatter()
const sum = (column) => {
  return props.tableItems.reduce((total, i) => total + parseFloat(i[column]), 0);
}

const totalSum = () => {
  let total = 0;
  props.tableItems.forEach((item)=>{
    total += item.total_hours * item.contact_person_fees
  })
  return total;
}
</script>
