<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showAddNew="false"
          title="Project Time sheet Report"
      />
    </div>

    <div class="my-2 px-2 row">
      <div class="col-12 col-sm-4 col-md-4">
        <div class="d-flex align-items-center justify-content-between filter-label">
          <label for="colFormLabel" class="col-form-label">Client: </label>
          <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                <i class="fas fa-search"></i>
            </span>
        </div>
        <AsyncSelect
            @onClear="onClearParty"
            :option-selected="getChangeParty"
            placeholder="Select Client"
            v-model="contactProfile"
            :api-service="fetchContactProfiles"
            :additional-query="additionalContactOption"
            :format-label="formatPatientLabel"
        />
      </div>
      <div class="col-12 col-sm-4 col-md-4">
        <div class="d-flex align-items-center justify-content-between filter-label">
          <label for="colFormLabel" class="col-form-label">Select Project: </label>
        </div>
        <AsyncSelect
            ref="projectSelectField"
            placeholder="Select Project Director"
            v-model="project"
            :api-service="fetchProjects"
            :additional-query="projectAdditionalQuery"
        />
      </div>
      <div class="col-md-4 my-auto">
        <div class="d-flex gap-1 justify-content-between mt-2">
          <button class="btn btn-primary btn-block waves-effect waves-float waves-light" @click="onClickGo">
            Submit
          </button>
          <button @click="exportTable"
                  class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect">
            Export
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 px-2 mt-2">
      <div v-if="projectInfo.party_name">
        <div class="h5"><strong>Client Name:</strong> {{ projectInfo.party_name }}</div>
        <div class="h5"><strong>Project Name:</strong> {{ projectInfo.project_name }}</div>
        <div class="h5"><strong>Est. Start & End Date:</strong> {{ projectInfo.start_date }}
          & {{ projectInfo.end_date }}
        </div>
        <div class="h5"><strong>Actual Start & End Date:</strong>
          {{ projectInfo.first_activity_date }} &
          {{ projectInfo.completed_date_time }}
        </div>
      </div>
      <br>
      <ListTable
          :tableItems="projectTimesheetReport"
      />
    </div>
  </div>
  <ContactDropdownFilter
      v-if="store.state.isModalOpenThree"
      @onSearchContact="onSearchContact"
      :companyRoles="companyRoles"
      :isFilterable="true"
  />
  <Loader v-if="loading"/>
</template>

<script setup>
import Loader from '@/components/atom/LoaderComponent'
import {ref, computed, inject, onMounted} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import TitleButton from '@/components/atom/TitleButton'
import handleCompanyContact from "@/services/modules/companyContact";
import handleProjects from "@/services/modules/procurement/project";
import ListTable from "@/components/molecule/company/contact/ProjectTimesheetReportTableList.vue";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleContact from "@/services/modules/contact";
import filterNullFromObject from "@/services/utils/filterNullFromObject";
import handleRole from "@/services/modules/role";

const {fetchProjectTimesheetReport} = handleCompanyContact();
const {fetchProjects} = handleProjects();
const {fetchContactProfiles} = handleContact();
const {formatPatientLabel} = useAsyncDropdownHelper();
const {fetchCompanyDefaultRoles} = handleRole();

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const route = useRoute()
const router = useRouter()
const store = useStore()
const companyRoles = ref([])
let loading = ref(false)
//computed
const companyId = computed(() => route.params.companyId);
const startDate = computed(() => route.query.start);
const endDate = computed(() => route.query.end);
const token = computed(() => localStorage.getItem('token'));

const project = ref(null)
const contactProfile = ref(null)

const additionalContactOption = ref({
  with_mobile: 1,
  dealer_id: ''
});
const projectAdditionalQuery = ref({
  with_donors: 1,
  party_id: ''
})
let projectSelectField = ref(null)
const getChangeParty = (value) => {
  projectAdditionalQuery.value.party_id = value.id
  projectSelectField.value.clear()
}

const onClearParty = () => {
  projectAdditionalQuery.value.party_id = ''
  projectSelectField.value.clear()
}

const onOpenContactSearchModal = () => {
  store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
  contactProfile.value = contact
}

const projectTimesheetReport = ref([]);
const projectInfo = ref({});

function getQuery() {
  let query = '?company_id=' + companyId.value
  if (!startDate.value && !endDate.value) return query
  if (contactProfile.value) query += '&contact_profile_id=' + contactProfile.value.id
  if (project.value) query += '&project_id=' + project.value.id
  if (startDate.value) query += '&start_date=' + startDate.value
  if (endDate.value) query += '&end_date=' + endDate.value
  return query
}

const exportTable = () => {
  if (!contactProfile.value) {
    return showError('Please select Client first!')
  }
  if (!project.value) {
    return showError('Please select Project first!')
  }
  const queryObject = {
    company_id: companyId.value,
    start_date: startDate.value,
    end_date: endDate.value,
    contact_profile_id: contactProfile.value.id,
    project_id: project.value.id,
    _token: token.value
  }

  const queryString = new URLSearchParams(filterNullFromObject(queryObject)).toString();

  let url = `${process.env.VUE_APP_BASE_URL}/export/project-timesheet-report?${queryString}`
  let a = document.createElement("a")
  a.setAttribute("href", url)
  a.click()
}

function onClickGo() {
  if (!contactProfile.value) {
    return showError('Please select Client first!')
  }
  if (!project.value) {
    return showError('Please select Project first!')
  }
  fetchProjectTimesheetReport(getQuery()).then(({data, status, project_data}) => {
    if (!status) {
      projectTimesheetReport.value = []
      return projectInfo.value = {}
    }

    projectTimesheetReport.value = data
    projectInfo.value = project_data;
  })
}

const getCompanyRoles = () => {
  fetchCompanyDefaultRoles(`?company_id=${companyId.value}`).then(({data, status}) => {
    if (status) {
      companyRoles.value = data
    }
  }).catch(err => {
    showError(err.message)
  }).finally(() => {
    loading.value = false;
  })
}

onMounted(() => {
  loading.value = true;
  getCompanyRoles()
})

</script>
